<template>
  <div
    class="flex justify-center items-center h-screen w-screen bg-center bg-cover bg-[#042A3A]"
    :style="{
      backgroundImage: 'url(' + imageUrl + ')',
    }"
    @click="pageClickHandler"
  >
    <!-- Clear Button -->
    <div v-if="showClearButton" class="absolute left-2 top-3">
      <button class="text-4xl" @click="removeClickHandler" id="clear-button">
        <i class="fa-regular fa-lg fa-trash text-white hover:text-rose-500"></i>
      </button>
    </div>
    <!-- /Clear Button -->

    <!-- Text Message -->
    <div v-if="showTextMessage" class="mx-20">
      <p
        class="leading-normal text-gray-100 bg-slate-200 bg-opacity-30 rounded-lg inline-flex p-3 text-center"
        :style="{ fontSize: fontSize }"
      >
        {{ messageText }}
      </p>
    </div>
    <!-- /Text Message -->

    <!-- Text Input -->
    <div v-if="showTextArea" class="relative rounded">
      <div
        class="border-gray-300 rounded-lg overflow-hidden w-72 bg-slate-200 bg-opacity-30"
      >
        <textarea
          rows="10"
          name="message"
          id="message"
          class="py-3 px-2 border-0 resize w-full bg-slate-200 bg-transparent"
          v-model="messageText"
          :maxlength="maxAllowedCharacters"
        />

        <!-- Spacer element to match the height of the toolbar -->
        <div class="p1-2" aria-hidden="true">
          <!-- Matches height of button in toolbar (1px border + 36px content height) -->
          <div class="py-px">
            <div class="h-5" />
          </div>
        </div>
      </div>

      <div
        class="absolute bottom-0 inset-x-0 pl-1 pr-1 py-1 flex space-x-2 justify-end"
      >
        <div class="flex-grow self-center">
          <span
            class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-green-800"
            :class="totalCharacters > 200 ? 'bg-yellow-200' : 'bg-green-100'"
            >{{ totalCharacters }}/{{ maxAllowedCharacters }}</span
          >
        </div>
      </div>
    </div>
    <!-- /Text Input -->

    <!-- Image Picker -->
    <div v-if="!viewMode" class="absolute bottom-10">
      <input
        type="file"
        class="hidden"
        ref="fileInput"
        accept="image/*"
        @change="onSelectImage"
      />
      <button
        @click="$refs.fileInput.click()"
        class="inline-flex items-center justify-center text-4xl p-3 text-indigo-100 transition-colors duration-150 bg-cyan-400 rounded-lg focus:shadow-outline hover:bg-cyan-600"
      >
        <i class="fa-solid fa-image pt-1"></i>
      </button>
    </div>
    <!-- /Image Picker -->
  </div>
</template>

<script>
import { dbRef, onValue, update } from "../firebaseInit";
import { app } from "../firebaseInit";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import debounce from "lodash/debounce";

const storage = getStorage(app);

export default {
  name: "PanelComponent",
  props: { viewMode: Boolean },
  data() {
    return {
      messageText: "",
      imageUrl: "",
      imageFile: null,
      textEditMode: false,
      maxAllowedCharacters: 300,
    };
  },
  mounted() {
    onValue(dbRef, (snapshot) => {
      // Here we are using the snapshot to get the data from the database(this keeps data up to date)
      this.messageText = snapshot.val().message;
      this.imageUrl = snapshot.val().imageUrl;
    });
  },
  methods: {
    pageClickHandler(e) {
      const prohibitedElementIds = ["message", "clear-button"];
      if (!prohibitedElementIds.includes(e.target.id)) {
        this.textEditMode = !this.textEditMode;
      }
    },
    removeClickHandler() {
      this.messageText = "";
      this.imageUrl = "";
      this.imageFile = null;
      this.$refs.fileInput.value = "";
      update(dbRef, { message: "", imageUrl: "" });
    },
    onSelectImage(event) {
      // Here we handle image uploading
      const file = event.target.files[0];

      if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
        return alert("Please select a correct image file.");
      }

      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);
      this.imageFile = file;

      // Upload file and metadata to the object 'images/<image file name>'
      const storageRef = ref(storage, "images/image.png");
      uploadBytes(storageRef, this.imageFile).then((snapshot) => {
        // Get the download URL from firebase storage
        getDownloadURL(snapshot.ref).then((url) => {
          this.imageUrl = url;
          update(dbRef, { imageUrl: url });
        });
      });
    },
    updateMessage: debounce(function () {
      // Here we handle message updating using debounce
      update(dbRef, { message: this.messageText });
    }, 500),
  },
  computed: {
    // Maybe not the best way to do this, but it works for now
    fontSize() {
      return this.messageText.length <= 30
        ? "8rem"
        : this.messageText.length <= 50
        ? "7rem"
        : this.messageText.length <= 70
        ? "6rem"
        : this.messageText.length <= 189
        ? "4rem"
        : "3rem";
    },
    showClearButton() {
      return this.viewMode
        ? false
        : this.messageText.length > 0 || this.imageUrl.length > 0;
    },
    showTextMessage() {
      // Defines whether or not to show the text message
      return !this.viewMode
        ? this.messageText.length > 0 && !this.textEditMode
        : this.messageText.length > 0;
    },
    showTextArea() {
      // Defines whether or not to show the text area
      return this.viewMode ? false : this.textEditMode;
    },
    totalCharacters() {
      return this.messageText.length;
    },
  },
  watch: {
    messageText() {
      // Here we handle message updating on changing the message text
      this.updateMessage();
    },
  },
};
</script>
<style scoped>
/* Vue transition specific styles*/
.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
