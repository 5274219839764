import PanelComponent from "../components/PanelComponent";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/controller",
      name: "controller",
      component: PanelComponent,
      props: { viewMode: false },
    },
    {
      path: "/",
      name: "home",
      component: PanelComponent,
      props: { viewMode: true },
    },
  ],
});

export default router;
